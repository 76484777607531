import { ParadiseDevicePointMessageDialog } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDevicePointMessages/ParadiseDevicePointMessageDialog"
import { getPointMessageType } from "src/data/devices/logic/deviceLogic"
import { IParadiseDevicePointMessage } from "src/data/paradise/paradiseDevices/types/paradiseDeviceQueryTypes"
import { MBadge } from "src/ui/MBadge/MBadge"
import { formatUtcDate } from "src/utils/l10n"

export function ParadiseDevicePointMessagesRow({
  message,
  deviceId,
  open,
  onClose,
}: {
  message: IParadiseDevicePointMessage
  deviceId: string
  open: boolean
  onClose: () => void
}) {
  return (
    <>
      <div>
        <div>
          <MBadge size="x-small">
            {getPointMessageType(message.message_type)}
          </MBadge>
        </div>
      </div>
      <div>
        {formatUtcDate({
          date: new Date(message.timestamp_ms).toISOString(),
        })}
      </div>
      <ParadiseDevicePointMessageDialog
        open={open}
        onClose={onClose}
        deviceId={deviceId}
        message={message}
      />
    </>
  )
}
