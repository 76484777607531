import { useState } from "react"

import {
  SettingContainer,
  TSettingContainerOnSaveReturnType,
} from "src/components/Settings/Setting/SettingContainer"
import { MultipleValuesField } from "src/ui/MultipleValuesField/MultipleValuesField"
import { spacing } from "src/ui/spacing"

export function ParadiseSettingMultiValueFields({
  title,
  displayValue,
  initialTextFields,
  buttonLabel,
  onSave,
  required,
  disabled,
}: {
  title: React.ReactNode
  displayValue: React.ReactNode
  initialTextFields: string[]
  buttonLabel: string
  onSave: (v: string[]) => TSettingContainerOnSaveReturnType
  required?: boolean
  disabled?: boolean
}) {
  const [saveValue, setSaveValue] = useState<string[]>(initialTextFields)

  return (
    <SettingContainer
      title={title}
      displayValue={displayValue}
      gap={spacing.XS2}
      titleProps={{
        variant: "nano",
        color: "secondary",
      }}
      InputComponent={() => (
        <MultipleValuesField
          textFields={saveValue}
          onChange={(textFields) => {
            setSaveValue(textFields)
          }}
          buttonLabel={buttonLabel}
          required={required}
        />
      )}
      onSave={() => {
        return onSave(saveValue)
      }}
      onClose={() => {
        setSaveValue(initialTextFields)
      }}
      disabled={disabled}
    />
  )
}
